@font-face {
    font-family: 'SourceSansPro-Regular';
    src: url('../fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-Regular.woff') format('woff'), url('../fonts/SourceSansPro-Regular.ttf') format('truetype'), url('../fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeue.woff') format('woff'), url('../fonts/HelveticaNeue.ttf') format('truetype'), url('../fonts/HelveticaNeue.svg#HelveticaNeue') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue-Bold';
    src: url('../fonts/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/HelveticaNeue-Bold.woff') format('woff'), url('../fonts/HelveticaNeue-Bold.ttf') format('truetype'), url('../fonts/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

.col-md-12{
    padding:0;
}
html {
    height: 100%;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    position: relative;
    margin: 0;
    padding-bottom: 75px !important;
    min-height: 100%;
    font-family: "SourceSansPro-Regular", sans-serif;
    font-size: 14px;
}

a, button {
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
}

a {
    text-decoration: none;
}

a:focus, a:hover, a:active {
    outline: 0;
    box-shadow: 0;
}

abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: 0 0
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

ul {
    list-style: none;
    color: #000;
}

blockquote, q {
    quotes: none
}

blockquote:after, blockquote:before, q:after, q:before {
    content: '';
    content: none
}

a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: 0 0;
    outline: 0 !important;
    transition: all 0.5s ease-in-out;
}

ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none
}

mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: 700
}

del {
    text-decoration: line-through
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0
}

input, select {
    vertical-align: middle
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.1em;
    font-family: "HelveticaNeue", sans-serif;
    color: #000;
}

h1 {
    font-size: 40px;
}

h3 {
    font-size: 32px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 16px;
}

p {
    line-height: 1.1em;
}

/*=== Custome Styles ===*/

.pad_left_zero {
    padding-left: 0;
}

.pad_right_zero {
    padding-right: 0;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.section-heading * {
    letter-spacing: 2px;
}

.logo-section {
    text-align: center;
    padding: 10px 0 14px;
    background-color: #f0f0f2;
}

.logo-section .image-holder {
    display: inline-block;
}

.logo-section h5 {
    color: #000000;
    font-family: "HelveticaNeue", sans-serif;
    letter-spacing: 8px;
    margin-bottom: 4px;
    margin-top: 5px;
}

.main-header .nav_outer_main {
    background: #302ca7 url("../img/white-bg.png") no-repeat scroll left top / 30% 67px;
    position: relative;
}

.main-header .navbar-default {
    background-color: transparent;
    border: none;
    margin-bottom: 0;
}

.lang_nav {
    background-color: #fff;
    padding: 0 90px;
}

.lang_nav ul li {
    display: inline-block;
}

.lang_nav ul li a {
    width: 52px;
    color: #302ca7;
    display: inline-block;
    font-family: "HelveticaNeue", sans-serif;
    font-size: 16px;
    padding: 22px 12px;
    position: relative;
    text-decoration: none;
    vertical-align: middle;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}

.lang_nav ul li a:focus, .lang_nav ul li a:active, .lang_nav ul li a.active {
    padding-top: 21px;
    font-family: "HelveticaNeue-Bold", sans-serif;
}

.lang_nav ul li a::after {
    content: "|";
    font-family: "HelveticaNeue", sans-serif;
    position: absolute;
    right: -2px;
    font-weight: normal;
}

.lang_nav ul li:last-child a::after {
    content: "";
}

.navbar-default .main_nav.navbar-nav {
    padding-left: 160px;
}

.navbar-default .main_nav.navbar-nav > li > a {
    color: #ffffff;
    font-family: "HelveticaNeue", sans-serif;
    font-size: 16px;
    padding: 23px 29px;
}

.navbar-default .main_nav.navbar-nav > li > a:hover, .navbar-default .main_nav.navbar-nav > li > a:focus, .navbar-default .main_nav.navbar-nav > li > a.active {
    background: #7370c3;
    color:white;
}

.page_heading {
    text-align: center;
    margin-bottom: 66px;
}

.page_heading h3 {
    margin-bottom: 1px;
    letter-spacing: 2px;
}

.section_main {
    padding: 56px 0 0px;
}

.section_text p {
    color: #6e6e6e;
    font-family: "SourceSansPro-Regular", sans-serif;
    font-size: 18px;
    margin-bottom: 20px;
    padding: 0 17px;
    text-align: justify;
}
.section_text a{
    color: #302ca7;
    font-family: "HelveticaNeue-Bold", sans-serif;
    text-decoration: none;
}

.section_bottom {
    margin-top: 100px;
    margin-bottom: 75px;
}

.section_bottom .container {
    background: url("../img/bg-home.jpg") no-repeat 0 0 / cover;
}

.about_bottom {
    margin-top: 250px;
}

.about_bottom .container {
    background: url("../img/bg-about.jpg") no-repeat 0 0 / cover;
}

.rates_bottom .container {
    background: url("../img/bg-rates.jpg") no-repeat 0 0 / cover;
}

.rates_bottom {
    margin-top: 350px;
}

.contact_bottom .container {
    background: url("../img/bg-contact.jpg") no-repeat 0 0 / cover;
}

.contact_bottom {
    margin-top: 330px
}

.text_quote {
    margin: 0 auto;
    padding-top: 70px;
    width: 610px;
}

.rates_bottom .text_quote,.contact_bottom .text_quote {
    padding-top: 33px;
}

.text_quote h5 {
    font-family: "SourceSansPro-Regular", sans-serif;
    font-size: 18px;
    line-height: 1.9em;
    color: #fff;
    letter-spacing: 2px;
}

.image_quote {
    text-align: center;
}

.image_quote .image-holder img {
    margin: 0 auto;
    -webkit-box-shadow: -2px 3px 99px 0px rgba(13, 14, 26, 0.68);
    -moz-box-shadow: -2px 3px 99px 0px rgba(13, 14, 26, 0.68);
    box-shadow: -2px 3px 99px 0px rgba(13, 14, 26, 0.68);
}

.footer_section {
    padding: 13px 0 0px;
    position: relative;;
    width: 100%;
}

.footer-wrapper{
    background-color: #f0f0f2;
}
.footer_section p {
    font-family: "HelveticaNeue", sans-serif;
    font-size: 14px;
    margin-top: 18px;
    color: #000;
}

.footer_section p a {
    color: #302ca7;
}

.social_icons ul li a {
    color: #1275b1;
    font-size: 34px;
}

.footer_email p {
    display: inline-block;
    margin-top: 18px;
    padding-left: 20px;
    margin-right: 50%;
    vertical-align: middle;
}

.audio_btns {
    position: absolute;
    right: 0;
    top: 19px;
    z-index: 1;
}

@-moz-document url-prefix() {
    .audio_btns {
        top: 14px;
    }
}

.audio_btn {
    outline: none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 26px 26px;
    height: 25px;
    width: 25px;
    border: none;
    background-image: url("../img/play.png");
}

.audio_btn.pause {
    background-image: url("../img/pause.png") !important;
}

.webclix-logo{
    position: absolute;
    bottom: 8px;
    right: 35px;
    width: 33px;
    display: block;
}

/*========================================================
================== RESPONSIVE QUERIES===============*/

/*------------------------------------------------
1300
------------------------------------------------*/
@media screen and (max-width: 1300px) {
    .container {
        width: 1170px;
    }
}

/*------------------------------------------------
1200
------------------------------------------------*/
@media (min-width: 1200px) {

}

/*------------------------------------------------
1199
------------------------------------------------*/
@media (max-width: 1199px) {
    .container {
        width: 970px;
    }

    .lang_nav {
        padding: 0 10px;
    }

    .navbar-default .main_nav.navbar-nav > li > a {
        padding: 23px 17px;
    }
}

/*------------------------------------------------
1059
------------------------------------------------*/
@media screen and (max-width: 1059px) {

}

/*------------------------------------------------
992
------------------------------------------------*/
@media (min-width: 992px) {

}

/*------------------------------------------------
991
------------------------------------------------*/
@media (max-width: 991px) {
    .container {
        width: 750px;
    }

    .navbar-default .main_nav.navbar-nav {
        padding-left: 29px;
    }

    .lang_nav {
        padding: 0;
    }

    .footer_email p {
        padding-left: 10px;
    }

    .text_quote {
        width: 100%;
    }
    .text_quote h5 {
        font-size: 16px;
        line-height: 1.8em;
    }
}


/*------------------------------------------------
768
------------------------------------------------*/
@media (min-width: 768px) {

}

/*------------------------------------------------
767
------------------------------------------------*/
@media (max-width: 767px) {
    .container {
        width: 100%;
    }

    .lang_nav {
        background-color: transparent;
    }

    .nav_outer_main {
        background: #302ca7 !important;
        position: relative;
        padding: 10px 0;
    }

    .lang_nav {
        position: absolute;
        top: -17px;
        left: 47px;
    }

    .nav.navbar-nav.lang_nav a {
        color: #fff;
    }

    .audio_btns {
        right: inherit;
        top: 10px;
        left: 2px;
    }

    @-moz-document url-prefix() {
        .audio_btns {
            top: 6px;
        }
    }
    .pad_left_zero, .pad_right_zero {
        padding-left: 15px;
        padding-right: 15px;
    }

    .text_quote {
        margin: 0 auto;
        padding-bottom: 40px;
        padding-top: 50px;
        text-align: center;
    }

    .text_quote .pull-right {
        float: none !important;
    }

    .image_quote .image-holder img {
        margin: 0 auto 50px;
    }

    .navbar-default .navbar-collapse, .navbar-default .navbar-form {
        border: none;
    }

    .navbar-collapse {
        box-shadow: none;
    }

    .navbar-default .main_nav.navbar-nav {
        padding-left: 0;
    }
    body {
        padding-bottom: 150px !important;
    }
}

@media (min-height: 900px) and (max-width: 767px) {
    body {
        padding-bottom: 0 !important;

    }
    .footer_section {
        position: relative;
    }
}
/*------------------------------------------------
600
------------------------------------------------*/
@media (max-width: 600px) {
}

/*------------------------------------------------
554
------------------------------------------------*/
@media (max-width: 554px) {
    .logo-section h5 {
        letter-spacing: 2px;
    }
}

/*------------------------------------------------
500
------------------------------------------------*/
@media (max-width: 510px) {
    .footer_section .col-xs-4, .footer_section .social_icons, .footer_section .footer_email {
        text-align: center !important;
    }

    .footer_section .footer_email {
        width: 100% ;
        margin-bottom: 10px;
        text-align: center !important;
    }
    .footer_email p {
        display: inline-block;
        margin-top: 0;
        padding-left: 11px !important;
        vertical-align: middle;
        width: 100%;
    }
    .footer_section .social_icons {
        display: inline-block;
        float: none;
        vertical-align: middle;
        width: 58px;
    }
    .footer_section .pad_left_zero{
        width: 100%;
        margin-bottom: 15px;
    }


    .page_heading h3 {
        letter-spacing: 1px;
    }

    .section_text p {
        font-size: 16px;
        letter-spacing: 1px;
    }
}

/*------------------------------------------------
479
------------------------------------------------*/
@media (max-width: 479px) {
}

/*------------------------------------------------
375
------------------------------------------------*/
@media (max-width: 375px) {
    .footer_section .social_icons {
        width: 46px;
    }
}

/*------------------------------------------------
320
------------------------------------------------*/
@media (max-width: 320px) {

}

